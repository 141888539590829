/* eslint-disable*/
// material
import { Container, Stack, Box, LinearProgress, Card, Button, TextField } from '@material-ui/core';
// components
import Page from '../../components/Page';
import { RootState, useDispatch, useSelector } from '../../redux/store';
import { getPdfs } from '../../redux/slices/pdf';
import { useState, useEffect } from 'react';
import {
  PdfListTable,
  PdfCardList,
} from '../../components/pdf';
import { useFormik, Form, FormikProvider } from 'formik';
import { Api } from '../../@types/api';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { MHidden } from '../../components/@material-extend';
import { PATH_DASHBOARD } from '../../routes/paths';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

export default function PdfList() {
  const dispatch = useDispatch();
  const { isLoading, info, filters } = useSelector(
    (state: { pdf: Api.PdfListState }) => state.pdf
  );

  const formik = useFormik<Api.Filter>({
    initialValues: {
      per_page: filters.per_page,
      page: filters.page,
      order: filters.order,
      order_by: filters.order_by,
      keyword: filters.keyword
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { values, resetForm, handleSubmit, getFieldProps, isSubmitting, initialValues } = formik;

  useEffect(() => {
    dispatch(getPdfs(values));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPdfs(values));
  }, [dispatch, values]);

  return (
    <Page title="PDF List">
      <Container>
        <HeaderBreadcrumbs
          heading="PDF List"
          links={[
            { name: 'PDF List', href: PATH_DASHBOARD.root },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.pdf.new}
              startIcon={<Icon icon={plusFill} />}
            >
              PDFをアップロード
            </Button>
          }
        />

        {values && isLoading && (
          <LinearProgress sx={{ mb: 2 }} />
        )}

        <Box sx={{ mb: 2 }}>
          <FormikProvider value={formik}>
            <TextField
              fullWidth
              label="ファイル名で絞り込む"
              {...getFieldProps('keyword')}
              error={false}
              helperText={''}
            />
          </FormikProvider>
        </Box>

        <MHidden width="smDown">
          <Card sx={{ p: 1, pb: 2 }}>
            <PdfListTable
              info={info}
              isLoad={!info && !initialValues}
              formik={formik}
            />
          </Card>
        </MHidden>

        <MHidden width="smUp">
          <PdfCardList 
            info={info}
            isLoad={!info && !initialValues}
            formik={formik}
          />
        </MHidden>
      </Container>
    </Page>
  );
}
/* eslint-disable*/
