/* eslint-disable*/
import { map, filter, orderBy } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { axiosPerksInstance as axios, axiosKolInstance as axiosKol } from '../../utils/axios';
import { Api } from '../../@types/api';

// ----------------------------------------------------------------------

export type HeatmapData = {
  id: number;
  index: number;
  time: number;
  view_count: number;
  isInview: boolean;
}

export type PdfActionParam = {
  pdf_id: string;
  token: string;
}

const initialState: Api.PdfListState = {
  isLoading: false,
  error: false,
  info: {
    data: [],
    current_page: 1,
    per_page: 10,
    from: 0,
    to: 20,
    total: 0
  },
  pdf: null,
  pdf_action: null,
  filters: {
    per_page: 10,
    page: 1,
    order_by: 'id',
    order: 'desc',
    keyword: ''
  },
  pdf_pages: [],
};

export const uploadPdfs = createAsyncThunk<
  Api.PdfList,
  (File | string)[],
  {
    rejectValue: {
      error: any;
    };
  }
>(
  'pdf/uploadPdfs',
  async (files: (File | string)[], thunk) => {
    try {
      const params = new FormData();
      files.forEach((f, i) => {
        params.append('files[]', f);
      });
      const response = await axios.post('/api/owner/pdfs/add', params, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      response.data.forEach((h: any) => params.append('hashes[]', h));
      // await axiosKol.post('/pdfs', params, {
      //   headers: {
      //     'content-type': 'multipart/form-data',
      //   },
      // });
      return response.data;
    } catch (error) {
      return thunk.rejectWithValue({
        error: error
      });
    }
  }
)

export const uploadPdfsDev = createAsyncThunk<
  Boolean,
  { file: File | string; hash: string; },
  {
    rejectValue: {
      error: any;
    };
  }
>(
  'pdf/uploadPdfs',
  async (p, thunk) => {
    try {
      const params = new FormData();
      params.append('files[]', p.file);
      params.append('hashes[]', p.hash);
      await axiosKol.post('/pdfs', params, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      return true;
    } catch (error) {
      return thunk.rejectWithValue({
        error: error
      });
    }
  }
)

export const createPdfAction = createAsyncThunk<
  Api.PdfAction,
  PdfActionParam,
  {
    rejectValue: {
      error: any;
    };
  }
>(
  'pdf/createPdfAction',
  async (params: PdfActionParam, thunk) => {
    try {
      const response = await axios.post('/api/owner/pdfActions/add', params);
      return response.data;
    } catch (error) {
      return thunk.rejectWithValue({
        error: error
      });
    }
  }
)

const slice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET 
    getPdfsSuccess(state, action) {
      state.isLoading = false;
      state.info = action.payload;
    },

    getPdfSuccess(state, action) {
      state.isLoading = false;
      state.pdf = action.payload;
    },

    getPdfPagesSuccess(state, action) {
      state.isLoading = false;
      state.pdf_pages = action.payload.pdf?.pdf_pages || [];
    },

    // UPDATE
    updatePdfDataSuccess(state, action) {
      state.isLoading = false;
    },

    filterPdfs(state, action) {
      state.filters.per_page = action.payload.perPage;
      state.filters.page = action.payload.page;
      state.filters.order = action.payload.order;
      state.filters.order_by = action.payload.orderBy;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadPdfs.fulfilled, (state, action) => {
      state.info = action.payload
      state.isLoading = false;
    })
    builder.addCase(uploadPdfs.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(uploadPdfs.rejected, (state, action) => {
      if (action.payload) {
        state.isLoading = false;
        state.error = action.payload.error;
      }
    });

    builder.addCase(createPdfAction.fulfilled, (state, action) => {
      state.pdf_action = action.payload;
      state.isLoading = false;
    })
    builder.addCase(createPdfAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createPdfAction.rejected, (state, action) => {
      if (action.payload) {
        state.isLoading = false;
        state.error = action.payload.error;
      }
    });
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  filterPdfs
} = slice.actions;

// ----------------------------------------------------------------------

export function getPdfs(params: Api.Filter) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/owner/pdfs', {
        params: {
          keyword: params.keyword,
          orderBy: params.order_by,
          order: params.order,
          page: params.page,
          perPage: params.per_page
        }
      });
      dispatch(slice.actions.getPdfsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPdf(hash: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/owner/pdf?hash=' + hash);
      dispatch(slice.actions.getPdfSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPdfPages(hash: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosKol.get('/pdfs/' + hash, {
        headers: {
          Accept: 'application/json'
        }
      });
      dispatch(slice.actions.getPdfPagesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePdfData(id: number, heatmapData: HeatmapData[]) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/owner/pdfActions/' + id + '/update', {
        pdf_page_actions_attributes: heatmapData
      });
      dispatch(slice.actions.updatePdfDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateContactUrl(hash: string, contact_url: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/owner/pdf/pdf/update', {
        hash,
        contact_url
      });
      dispatch(slice.actions.updatePdfDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
/* eslint-disable*/
