/* eslint-disable*/
import { useState } from 'react';
// material
import {
  Box,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { RootState, useDispatch, useSelector } from '../../../redux/store';
import { updateContactUrl, getPdfs } from '../../../redux/slices/pdf';
// components
import Scrollbar from '../../Scrollbar';
// routes
import { PATH_DASHBOARD, PATH_PUBLIC } from '../../../routes/paths';
//
import SortingSelectingHead from './SortingSelectingHead';
import SortingSelectingToolbar from './SortingSelectingToolbar';
import { Api } from '../../../@types/api';
import { fCurrency, fTimeFromSeconds, fPercent, fNumber } from 'utils/formatNumber';
import { fDate, fDateTime } from 'utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    id: 'filename',
    numeric: false,
    disablePadding: false,
    label: 'ファイル名'
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'アップロード日時'
  },
  {
    id: 'view_count',
    numeric: false,
    disablePadding: false,
    label: '総閲覧数'
  },
  {
    id: 'time',
    numeric: false,
    disablePadding: false,
    label: '総閲覧時間'
  },
  {
    id: 'heatmap',
    numeric: false,
    disablePadding: false,
    label: ''
  },
  {
    id: 'copy',
    numeric: false,
    disablePadding: false,
    label: ''
  }
];

// ----------------------------------------------------------------------

type SortingSelectingProps = {
  page: number;
  perPage: number;
  order: 'asc' | 'desc';
  orderBy: string;
  rows: Api.Pdf[];
  totalCount: number;
  onChange?: (selected: number[]) => void;
  onPageChange?: (page: number) => void;
  onPerPageChange?: (perPage: number) => void;
  onSortChange?: (order: 'asc' | 'desc', orderBy: string) => void;
}

export default function SortingSelecting({ page, perPage, order, orderBy, rows, totalCount, onChange, onPageChange, onPerPageChange, onSortChange }: SortingSelectingProps) {
  const dispatch = useDispatch();
  const { isLoading, filters } = useSelector(
    (state: { pdf: Api.PdfListState }) => state.pdf
  );

  const handleRequestSort = (property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    onSortChange && onSortChange(isDesc ? 'asc' : 'desc', property);
  };

  const handleChangePage = (newPage: number) => {
    onPageChange && onPageChange(newPage);
  };

  const handleChangePerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onPerPageChange && onPerPageChange(parseInt(event.target.value, 10));
    handleChangePage(1);
  };

  const emptyRows =
    page > 1 ? Math.max(0, (page - 1) * perPage - rows.length) : 0;


  const [openContactUrlDialog, setOpenContactUrlDialog] = useState<null | Api.Pdf>(null);
  const [newContactUrl, setNewContactUrl] = useState('');
  const handleOpenContactUrlDialog = (row: Api.Pdf) => {
    setOpenContactUrlDialog(row);
    setNewContactUrl(row.contact_url);
  };
  const handleCloseContactUrlDialog = () => {
    setOpenContactUrlDialog(null);
    setNewContactUrl('');
  };

  const handleSaveContactUrl = () => {
    if (!openContactUrlDialog?.hash) {
      return;
    }
    dispatch(updateContactUrl(openContactUrlDialog.hash, newContactUrl));
    dispatch(getPdfs(filters));
    handleCloseContactUrlDialog();
  };

  return (
    <div>
      {/* <SortingSelectingToolbar numSelected={selected.length} /> */}

      <Scrollbar>
        <TableContainer sx={{ minWidth: "100%" }}>
          <Table size="medium">
            <SortingSelectingHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-${index}`;

                return (
                  <TableRow
                    hover
                    key={row.id}
                  >
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      <Link href={`${PATH_PUBLIC.pdf.show}/${row.hash}`} target="_blank" variant="body2" color="primary.dark">
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ whiteSpace: "nowrap" }}>
                      {row.created_at && row.created_at != "" && fDateTime(row.created_at.replace(/\ /g, 'T'), '/')}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {fNumber(row.view_count)}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {fTimeFromSeconds(row.time)}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      <Button
                        variant="contained"
                        component={RouterLink}
                        to={`${PATH_DASHBOARD.pdf.heatmap}/${row.hash}`}
                      >
                        ヒートマップ
                      </Button>
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => handleOpenContactUrlDialog(row)}
                      >
                        {row.contact_url ? 'リンクの変更' : 'リンクの追加'}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={perPage}
          page={page - 1}
          labelRowsPerPage="表示件数"
          onPageChange={(e, page) => handleChangePage(page + 1)}
          onRowsPerPageChange={handleChangePerPage}
        />
      </Box>

      <Dialog open={Boolean(openContactUrlDialog)} onClose={handleCloseContactUrlDialog} maxWidth="sm" fullWidth>
        <DialogTitle>お問い合わせリンクの追加</DialogTitle>
        <DialogContent>
          <TextField 
            value={newContactUrl}
            fullWidth
            placeholder="URL"
            onChange={(e) => {
              setNewContactUrl(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSaveContactUrl} autoFocus>追加する</Button>
          <Button variant="outlined" onClick={handleCloseContactUrlDialog} color="inherit">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
/* eslint-disable*/
