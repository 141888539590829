/* eslint-disable*/
// material
import { Skeleton, Grid } from '@material-ui/core';
import { Api } from '../../@types/api';
import PdfsTable from './pdfs-table';

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(12)].map((_, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '115%', borderRadius: 2 }} />
      </Grid>
    ))}
  </>
);

type PdfListTableProps = {
  info: Api.PdfList;
  isLoad: boolean;
  formik: Api.FormikPropsPdfList;
};

export default function PdfListTable({ info, isLoad, formik, ...other }: PdfListTableProps) {
  const { values, getFieldProps, handleChange, setFieldValue } = formik;

  return (
    <div>
      {info.data && (
        <PdfsTable
          page={values.page}
          perPage={values.per_page}
          order={values.order}
          orderBy={values.order_by}
          rows={info.data}
          totalCount={info.total}
          onPageChange={(page: number) => setFieldValue('page', page)}
          onPerPageChange={(perPage: number) => setFieldValue('per_page', perPage)}
          onSortChange={(order: 'asc' | 'desc', orderBy: string) => {
            setFieldValue('order', order);
            setFieldValue('order_by', orderBy);
          }}
        />
      )}

      {isLoad && SkeletonLoad}
    </div>
  );
}
/* eslint-disable*/
