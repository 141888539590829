/* eslint-disable*/
import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fMonth(date: string | number | Date, separater: string = '-') {
  return format(new Date(date), 'yyyy' + separater + 'MM');
}

export function fDate(date: string | number | Date, separater: string = '-') {
  return format(new Date(date), 'yyyy' + separater + 'MM' + separater + 'dd');
}

export function fDateTime(date: string | number | Date, separater: string = '-') {
  return format(new Date(date), 'yyyy' + separater + 'MM' + separater + 'dd HH:mm');
}

export function fDateTimeSecond(date: string | number | Date, separater: string = '-') {
  return format(new Date(date), 'yyyy' + separater + 'MM' + separater + 'dd HH:mm:ss');
}

export function fDateTimeSuffix(date: string | number | Date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: string | number | Date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fDateRange(startDate: string | number | Date, endDate: string | number | Date) {
  const start = new Date(startDate);
  const startStr = fDate(start);
  const startMonthStr = fMonth(start);
  const end = new Date(endDate);
  const endStr = fDate(end);
  const endMonthStr = fMonth(end);
  const endOfMonth = new Date(end.getFullYear(), end.getMonth() + 1, 0);
  var now = new Date();

  if (startStr === endStr) { // 日が同じ場合
    if (startStr === fDate(new Date())) { // 今日
      return "今日"
    } else if (startStr === fDate(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1))) { // 昨日
      return "昨日"
    } else if (startStr === fDate(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2))) { // 一昨日
      return "一昨日"
    }
    return startStr;
  } else if (startMonthStr === endMonthStr) { // 月が同じ場合
    if (start.getDate() === 1 && end.getDate() === endOfMonth.getDate()) { // 月初から月末
      if (startMonthStr === fMonth(now)) { // 今月
        return "今月"
      } else if (startMonthStr === fMonth(new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()))) { // 先月
        return "先月"
      } else if (startMonthStr === fMonth(new Date(now.getFullYear(), now.getMonth() - 2, now.getDate()))) { // 先々月
        return "先々月"
      }
    }
  }

  return fDate(start, '/') + " ~ " + fDate(end, '/');
}

export function fMonthRange(startDate: string | number | Date, endDate: string | number | Date) {
  const start = new Date(startDate);
  const startMonthStr = fMonth(start);
  const end = new Date(endDate);
  const endMonthStr = fMonth(end);
  const endOfMonth = new Date(end.getFullYear(), end.getMonth() + 1, 0);
  var now = new Date();

  if (startMonthStr === endMonthStr) { // 月が同じ場合
    if (start.getDate() === 1 && end.getDate() === endOfMonth.getDate()) { // 月初から月末
      if (startMonthStr === fMonth(now)) { // 今月
        return "今月"
      } else if (startMonthStr === fMonth(new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()))) { // 先月
        return "先月"
      } else if (startMonthStr === fMonth(new Date(now.getFullYear(), now.getMonth() - 2, now.getDate()))) { // 先々月
        return "先々月"
      }
    }
  }

  return fMonth(start, '/') + " ~ " + fMonth(end, '/');
}
/* eslint-disable*/
