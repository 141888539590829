import { Theme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Card(theme: Theme) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.customShadows.z16,
          borderRadius: isMobile ? theme.shape.borderRadius : theme.shape.borderRadiusMd,
          position: 'relative',
          zIndex: 0 // Fix Safari overflow: hidden with border radius
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' }
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0)
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3)
        }
      }
    }
  };
}
