import axios from 'axios';
import { perksApiConfig, kolApiConfig } from '../config';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// ----------------------------------------------------------------------

const axiosPerksInstance = axios.create({
  baseURL: perksApiConfig.baseURL//process.env.NODE_ENV === 'development' ? 'http://ad.site.localhost:8000' : perksApiConfig.baseURL
});

const axiosKolInstance = axios.create({
  baseURL: kolApiConfig.baseURL//process.env.NODE_ENV === 'development' ? 'http://ad.site.localhost:8000' : perksApiConfig.baseURL
});

const logout = async () => {
  await firebase.auth().signOut();
}

axiosPerksInstance.interceptors.response.use(response => {
  if (process.env.NODE_ENV === 'development') console.log(response);
  return response;
}, error => {
  if (error.response.status === 401) {
    logout();
  } else if (error.response.status === 404) {
    // window.location.href = "/404";
    return;
  }
  return Promise.reject(error);
});

axiosPerksInstance.interceptors.request.use(request => {
  if (process.env.NODE_ENV === 'development') console.log('Starting Request: ', request);
  const user = firebase.auth().currentUser;
  if (user) {
    user.getIdToken().then(token => {
      if (process.env.NODE_ENV === 'development') console.log(token);
      request.headers.Authorization = `Bearer ${token}`;
    });
  }
  return request
})

axiosKolInstance.interceptors.response.use(response => {
  if (process.env.NODE_ENV === 'development') console.log(response);
  return response;
}, error => {
  if (error.response.status === 401) {
    logout();
  } else if (error.response.status === 404) {
    // window.location.href = "/404";
    return;
  }
  return Promise.reject(error);
});

axiosKolInstance.interceptors.request.use(request => {
  if (process.env.NODE_ENV === 'development') console.log('Starting Request: ', request);
  const user = firebase.auth().currentUser;
  if (user) {
    user.getIdToken().then(token => {
      if (process.env.NODE_ENV === 'development') console.log(token);
      request.headers.Authorization = `Bearer ${kolApiConfig.apiToken}`;
    });
  }
  return request
})

export { axiosPerksInstance, axiosKolInstance };
