/* eslint-disable*/
import { useEffect, useState, useCallback } from 'react';
import { paramCase } from 'change-case';
import { useParams, useLocation } from 'react-router-dom';
// material
import { Container } from '@material-ui/core';
// redux
import { useDispatch, useSelector, RootState } from '../../redux/store';
import { getPdfs } from '../../redux/slices/pdf';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { UploadMultiFile } from '../../components/upload';
import { useFormik } from 'formik';
import { Api } from '../../@types/api';
import { uploadPdfs } from '../../redux/slices/pdf';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function PdfCreate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [files, setFiles] = useState<(File | string)[]>([]);
  const { isLoading, info, filters } = useSelector(
    (state: { pdf: Api.PdfListState }) => state.pdf
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    [setFiles]
  );

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleRemove = (file: File | string) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  const handleUpload = async () => {
    const result = await dispatch(uploadPdfs(files));
    if (uploadPdfs.fulfilled.match(result)) {
      enqueueSnackbar('アップロードに成功しました', { variant: 'success' });
      navigate(PATH_DASHBOARD.pdf.root);
    }
    if (uploadPdfs.rejected.match(result)) {
      enqueueSnackbar('アップロードに失敗しました', { variant: 'error' });
    }
  };

  return (
    <Page title="PDFアップロード">
      <Container>
        <HeaderBreadcrumbs
          heading={'PDFアップロード'}
          links={[
            { name: 'PDFリスト', href: PATH_DASHBOARD.pdf.root },
            { name: 'PDFアップロード' }
          ]}
        />

        <UploadMultiFile
          showPreview={false}
          files={files}
          accept="application/pdf"
          onDrop={handleDropMultiFile}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          onUpload={handleUpload}
        />
      </Container>
    </Page>
  );
}
/* eslint-disable*/
