import { ReactNode } from 'react';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function PublicGuard({ children }: GuestGuardProps) {
  return <>{children}</>;
}
