/* eslint-disable*/
// material
import { Skeleton, Grid } from '@material-ui/core';
import { Api } from '../../@types/api';
import PdfPagesTable from './pdf-pages-table';
import PdfPagesTableSp from './pdf-pages-table-sp';
import { MHidden } from 'components/@material-extend';

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(12)].map((_, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '115%', borderRadius: 2 }} />
      </Grid>
    ))}
  </>
);

type PdfListTableProps = {
  info: Api.PdfPage[];
  isLoad: boolean;
};

export default function PdfPageTable({ info, isLoad, ...other }: PdfListTableProps) {

  return (
    <div>
      <MHidden width="smDown">
        <PdfPagesTable
          rows={info}
        />
      </MHidden>

      <MHidden width="smUp">
        <PdfPagesTableSp
          rows={info}
        />
      </MHidden>

      {isLoad && SkeletonLoad}
    </div>
  );
}
/* eslint-disable*/
