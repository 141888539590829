/* eslint-disable*/
import { useState } from 'react';
// material
import {
  Skeleton,
  Grid,
  Stack,
  Card,
  Typography,
  Link,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core';
import { RootState, useDispatch, useSelector } from '../../redux/store';
import { updateContactUrl, getPdfs } from '../../redux/slices/pdf';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { Api } from '../../@types/api';
import { PATH_PUBLIC, PATH_DASHBOARD } from 'routes/paths';
import { fDateTime } from 'utils/formatTime';
import { fNumber, fTimeFromSeconds } from 'utils/formatNumber';

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(12)].map((_, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '115%', borderRadius: 2 }} />
      </Grid>
    ))}
  </>
);

type PdfCardListProps = {
  info: Api.PdfList;
  isLoad: boolean;
  formik: Api.FormikPropsPdfList;
};

export default function PdfCardList({ info, isLoad, formik, ...other }: PdfCardListProps) {
  const dispatch = useDispatch();
  const { isLoading, filters } = useSelector(
    (state: { pdf: Api.PdfListState }) => state.pdf
  );
  const theme = useTheme();
  const { values, getFieldProps, handleChange, setFieldValue } = formik;

  const [openContactUrlDialog, setOpenContactUrlDialog] = useState<null | Api.Pdf>(null);
  const [newContactUrl, setNewContactUrl] = useState('');
  const handleOpenContactUrlDialog = (row: Api.Pdf) => {
    setOpenContactUrlDialog(row);
    setNewContactUrl(row.contact_url);
  };
  const handleCloseContactUrlDialog = () => {
    setOpenContactUrlDialog(null);
    setNewContactUrl('');
  };

  const handleSaveContactUrl = () => {
    if (!openContactUrlDialog?.hash) {
      return;
    }
    dispatch(updateContactUrl(openContactUrlDialog.hash, newContactUrl));
    dispatch(getPdfs(filters));
    handleCloseContactUrlDialog();
  };

  return (
    <div>
      {info.data && (
        <Stack spacing={2}>
          {info.data.map((row) => (
            <Card sx={{ py: 2, px: 1 }}>
              <Stack spacing={1}>
                <Typography variant="overline" color={theme.palette.grey[600]} sx={{ py: 1.5, px: 2, backgroundColor: theme.palette.grey[200], borderRadius: 1 }}>
                  ファイル名
                </Typography>
                <Link
                  href={`${PATH_PUBLIC.pdf.show}/${row.hash}`}
                  target="_blank"
                  variant="body2"
                  color="primary.dark"
                  sx={{
                    overflow: 'hidden',
                    WebkitLineClamp: 2,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    wordBreak: 'break-all',
                  }}
                >
                  {row.name}
                </Link>
                <TableContainer sx={{ minWidth: "100%", pt: 3 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          アップロード日時
                        </TableCell>
                        <TableCell>
                          総閲覧回数
                        </TableCell>
                        <TableCell>
                          総閲覧時間
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hover>
                        <TableCell>
                          {row.created_at && row.created_at != "" && fDateTime(row.created_at.replace(/\ /g, 'T'), '/')}
                        </TableCell>
                        <TableCell>
                          {fNumber(row.view_count)}
                        </TableCell>
                        <TableCell>
                          {fTimeFromSeconds(row.time)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ pt: 2 }}>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={`${PATH_DASHBOARD.pdf.heatmap}/${row.hash}`}
                  >
                    ヒートマップ
                  </Button>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => handleOpenContactUrlDialog(row)}
                  >
                    {row.contact_url ? 'リンクの変更' : 'リンクの追加'}
                  </Button>
                </Stack>
              </Stack>
            </Card>
          ))}
        </Stack>
      )}

      {isLoad && SkeletonLoad}

      <Dialog open={Boolean(openContactUrlDialog)} onClose={handleCloseContactUrlDialog} maxWidth="sm" fullWidth>
        <DialogTitle>お問い合わせリンクの追加</DialogTitle>
        <DialogContent>
          <TextField
            value={newContactUrl}
            fullWidth
            placeholder="URL"
            onChange={(e) => {
              setNewContactUrl(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSaveContactUrl} autoFocus>追加する</Button>
          <Button variant="outlined" onClick={handleCloseContactUrlDialog} color="inherit">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
/* eslint-disable*/
