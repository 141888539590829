/* eslint-disable*/
import { useState } from 'react';
// material
import {
  Box,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Link,
  Stack,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
// components
import Scrollbar from '../../Scrollbar';
// routes
import { PATH_DASHBOARD, PATH_PUBLIC } from '../../../routes/paths';
//
import SortingSelectingHead from './SortingSelectingHead';
import SortingSelectingToolbar from './SortingSelectingToolbar';
import { Api } from '../../../@types/api';
import { fCurrency, fPercent, fTimeFromSeconds, fNumber } from 'utils/formatNumber';
import { fDate, fDateTimeSecond } from 'utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    id: 'index',
    numeric: false,
    disablePadding: false,
    label: 'ページ'
  },
  {
    id: 'time',
    numeric: false,
    disablePadding: false,
    label: '閲覧時間'
  },
  {
    id: 'view_count',
    numeric: false,
    disablePadding: false,
    label: '閲覧回数'
  },
  {
    id: 'user_count',
    numeric: false,
    disablePadding: false,
    label: '閲覧ユーザー数'
  }
];

// ----------------------------------------------------------------------

type Anonymous = Record<string | number, string>;

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: Api.PdfPage[], comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type SortingSelectingProps = {
  rows: Api.PdfPage[];
}

export default function SortingSelecting({ rows }: SortingSelectingProps) {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 1 ? Math.max(0, (page - 1) * rowsPerPage - rows.length) : 0;

  const maxTime = Math.max(...rows.map((r) => r.time));
  const maxUserCount = Math.max(...rows.map((r) => r.user_count));
  const maxViewCount = Math.max(...rows.map((r) => r.view_count));

  return (
    <div>
      {/* <SortingSelectingToolbar numSelected={selected.length} /> */}

      <Scrollbar>
        <TableContainer sx={{ minWidth: "100%", height: 400 }}>
          <Table size="medium">
            <SortingSelectingHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-${index}`;

                  return (
                    <TableRow
                      hover
                      key={row.id}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ whiteSpace: "nowrap" }}>
                        {row.index}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography variant="body2" textAlign="center" sx={{ fontSize: 12, width: 120 }}>{fTimeFromSeconds(row.time)}</Typography>
                          <Box
                            sx={{
                              width: `${(row.time / maxTime) * 100 + 8}px`,
                              border: `solid ${row.time >= 0 ? '4' : '0'}px #FF4842`,
                              borderRadius: '4px'
                            }}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography variant="body2" textAlign="center" sx={{ fontSize: 12, width: 80 }}>{fNumber(row.view_count)}</Typography>
                          <Box sx={{ width: `${(row.view_count / maxViewCount) * 100}px`, border: `solid ${row.view_count >= 0 ? '4' : '0'}px #1890FF`, borderRadius: '4px' }} />
                        </Stack>
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography variant="body2" textAlign="center" sx={{ fontSize: 12, width: 80 }}>{fNumber(row.user_count)}</Typography>
                          <Box sx={{ width: `${(row.user_count / maxUserCount) * 100}px`, border: `solid ${row.user_count >= 0 ? '4' : '0'}px #FFC107`, borderRadius: '4px' }} />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {/* <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 30, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="表示件数"
          onPageChange={(e, page) => handleChangePage(page + 1)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box> */}
    </div>
  );
}
/* eslint-disable*/
