/* eslint-disable*/
import { Document, Page as PdfPage, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.js';
import { useState, useEffect, useMemo, createRef, useRef } from 'react';
import { experimentalStyled as styled, alpha, useTheme } from '@material-ui/core/styles';
import {
  Container,
  Stack,
  Button,
  IconButton,
  Typography,
  Drawer,
  Box,
  Link,
  Popover,
  useMediaQuery,
} from '@material-ui/core';
import { PDFDownloadLink } from '@react-pdf/renderer';
// components
import Page from '../../components/Page';
import SvgIconStyle from '../../components/SvgIconStyle';
import { MotionInView } from '../../components/animate';
import InViewMonitor from "react-inview-monitor";
import Scrollbar from '../../components/Scrollbar';
import getVariant from '../components-overview/extra/animate/getVariant';
import { MHidden } from '../../components/@material-extend';
import { SizeMe } from 'react-sizeme';
import { RootState, useDispatch, useSelector } from '../../redux/store';
import { getPdf, createPdfAction, HeatmapData, updatePdfData, getPdfPages } from '../../redux/slices/pdf';
import { Api } from '../../@types/api';
import { useParams, useLocation } from 'react-router-dom';
// hooks
import useWindowSize from 'hooks/useWindowSize';
import { kolApiConfig } from 'config';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const PageStyle = styled('div')({
  display: 'flex',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: 10,
  paddingBottom: theme.spacing(10),
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    paddingTop: 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const HeaderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 64,
  boxShadow: '0px 1px 0px rgba(145, 158, 171, 0.24)',
  padding: '16px 12px',
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 90,
  backgroundColor: '#fff',
  [theme.breakpoints.up('md')]: {
    height: 80,
  }
}));

const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    bottom: -7,
    zIndex: 1,
    width: 12,
    right: '50%',
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(45deg)',
    background: theme.palette.background.paper,
    borderRight: `solid 2px #F1C832`,
    borderBottom: `solid 2px #F1C832`
  }
}));

export default function Pdf() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [width, height] = useWindowSize();
  const [numPages, setNumPages] = useState<number | null>(null);
  const [time, setTime] = useState<number>(0);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoading, pdf, pdf_action, pdf_pages, filters } = useSelector(
    (state: { pdf: Api.PdfListState }) => state.pdf
  );
  const [heatmapData, setHeatmapData] = useState<HeatmapData[]>([]);
  const [renderdPages, setRenderdPages] = useState<number[]>([1, 2]);

  const [data, setData] = useState<Uint8Array | null>(null);
  const file = useMemo(() => ({ data }), [data]);

  const getUniqueStr = (myStrong?: number): string => {
    let strong = 1000;
    if (myStrong) strong = myStrong;
    return (
      new Date().getTime().toString(16) +
      Math.floor(strong * Math.random()).toString(16)
    );
  }

  const [token, setToken] = useState(getUniqueStr());
  const [pdfActionId, setPdfActionId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getPdf(id));
    dispatch(getPdfPages(id));
    const f = async () => {
      const result = await dispatch(createPdfAction({ pdf_id: id, token: token }));
      if (createPdfAction.fulfilled.match(result)) {
        setPdfActionId(result.payload.id);
      }
      if (createPdfAction.rejected.match(result)) {
      }
    };
    f();
  }, [dispatch]);

  useEffect(() => {
    if (!pdf || data) return;

    const f = async () => {
      const data = await getUint8Array(pdf.file);
      setData(data);
    };
    f().catch(err => console.log(err));
  }, [pdf]);

  useEffect(() => {
    console.log('start')
    const interval = setInterval(() => {
      setTime(p => p + 1);
      setHeatmapData(
        prev => {
          if (!prev) return [];
          return prev.map(p => {
            if (p.isInview == true) {
              return {
                id: p.id,
                index: p.index,
                time: p.time + 1,
                view_count: p.view_count,
                isInview: p.isInview
              }
            }
            return p
          })
        }
      )
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (time < 70 && time % 10 == 0 && pdfActionId) {
      dispatch(updatePdfData(pdfActionId, heatmapData));
    }
  }, [time]);

  const onDocumentLoadSuccess = (numPages: number) => {
    setNumPages(numPages);
    if (!pdf_action) return;

    const data = pdf_action.page_actions.map((p) => {
      return {
        id: p.id,
        index: p.index,
        time: p.time,
        view_count: p.view_count,
        isInview: false
      }
    })
    setHeatmapData(
      data
    );
  }

  const getUint8Array = async (url: string) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    const data = new Uint8Array(arrayBuffer);
    return data;
  }

  const handleInView = (data: HeatmapData) => {
    if (numPages && numPages < data.index) return;

    setHeatmapData(
      prev => {
        if (!prev) return [];
        return prev.map(p => {
          if (p.index === data.index) {
            return {
              id: data.id,
              index: data.index,
              time: data.time,
              view_count: data.view_count += 1,
              isInview: true
            }
          }
          return p
        })
      }
    )
  }

  const handleNotInView = (data: HeatmapData) => {
    setHeatmapData(
      prev => {
        if (!prev) return [];
        return prev.map(p => {
          if (p.index === data.index) {
            return {
              id: data.id,
              index: data.index,
              time: data.time,
              view_count: data.view_count,
              isInview: false
            }
          }
          return p
        })
      }
    )
  }

  const listRefs = useRef<(any)[]>([]);
  useEffect(() => {
    if (listRefs.current.length > 0) {
      return;
    }

    heatmapData.forEach((d: any, index: number) => {
      const newRef = createRef();
      listRefs.current[index] = newRef;
    });
  }, [heatmapData]);

  const [selectPage, setSelectPage] = useState('1');
  const handleChangePage = (page: string) => {
    const pageNum = !page ? 1 : Number(page);
    setSelectPage(String(pageNum));
    const pageIndex = pageNum < 0 ? 0 : pageNum - 1;
    if ((listRefs.current || []).length <= pageIndex) {
      return;
    }
    (listRefs.current[pageIndex].current as HTMLElement)?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleDownload = () => {
    if (!data || !pdf?.name) {
      return;
    }
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.download = pdf?.name;
    a.href = url;
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const [openSidebar, setOpenSidebar] = useState(!isMobile);
  const handleToggleSidebar = () => {
    setOpenSidebar(prev => !prev);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [isHoverButton, setIsHoverButton] = useState(false);

  return (
    <RootStyle>
      <HeaderStyle>
        <Stack direction="row" spacing={3} alignItems="center" sx={{ maxWidth: { xs: 155, md: '100%' } }}>
          {!isMobile && (
            <IconButton onClick={handleToggleSidebar}>
              <SvgIconStyle src="/static/icons/ic_double_arrow_left.svg" sx={{ width: 24, height: 24, color: '#000' }} />
            </IconButton>
          )}
          <Typography
            variant="subtitle2"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
            {pdf?.name}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={{ xs: 0.5, md: 3 }} alignItems="center">
          {pdf?.name && data && (
            <IconButton onClick={handleDownload}>
              <SvgIconStyle src="/static/icons/ic_download.svg" sx={{ width: { xs: 16, md: 24 }, height: { xs: 16, md: 24 }, color: '#000' }} />
            </IconButton>
          )}
          <Stack spacing={0.5} direction="row" alignItems="center">
            <input
              type="number"
              value={selectPage}
              ref={inputRef}
              onChange={(e) => setSelectPage(e.target.value)} style={{ width: 24, height: 18, textAlign: 'center' }}
              onBlur={() => handleChangePage(selectPage)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && inputRef.current) {
                  inputRef.current.blur();
                }
              }}
            />
            <Typography variant="subtitle2">{`/ ${(pdf?.pages || []).length}`}</Typography>
          </Stack>
        </Stack>
      </HeaderStyle>
      <Box sx={{ height: { xs: 74, md: 90 }, width: 100 }} />
      <PageStyle>
        <MHidden width="smDown">
          <Drawer
            open={openSidebar}
            sx={{ height: '100%' }}
            variant="persistent"
            PaperProps={{
              sx: {
                width: openSidebar ? { xs: '80%', md: 280 } : 0,
                zIndex: 20,
                position: 'inherit',
              }
            }}
          >
            <Scrollbar
              sx={{
                height: 1,
                '& .simplebar-content': {
                  height: 1,
                  display: 'flex',
                  flexDirection: 'column'
                }
              }}
            >
              <Stack sx={{ py: 2, px: { xs: 5, md: 7.5 } }}>
                {pdf && pdf_action && file.data && (
                  <Document
                    file={file}
                    onLoadSuccess={async (pdf) => {
                      console.log('end')
                      onDocumentLoadSuccess(pdf.numPages)
                    }}
                  >
                    {heatmapData.map((el, index) => (
                      <Link
                        onClick={() => {
                          handleChangePage(String(el.index));
                        }}
                        color="#000"
                        sx={{ textDecoration: 'none' }}
                      >
                        <Stack spacing={1} sx={{ mb: 3 }}>
                          <SizeMe>
                            {({ size }) => (
                              <div style={{ position: 'relative' }}>
                                <PdfPage key={index} pageNumber={el.index} width={size.width || 248} />
                              </div>
                            )}
                          </SizeMe>
                          <Typography variant="overline" textAlign="center">{el.index}</Typography>
                        </Stack>
                      </Link>
                    ))}
                  </Document>
                )}
              </Stack>
            </Scrollbar>
          </Drawer>
        </MHidden>

        <MainStyle>
          <Page title="PDF">
            <Container id="heat">
              {pdf && pdf_action && file.data && (
                <>
                  <Document
                    file={file}
                    onLoadSuccess={async (pdf) => {
                      console.log('end')
                      onDocumentLoadSuccess(pdf.numPages)
                    }}
                  >
                    {heatmapData.map((el, index) => (
                      <div key={index} ref={listRefs.current[index]} style={{ position: 'relative', margin: '1.5rem 0px', boxShadow: '0px 10px 10px -5px rgba(0,0,0,0.5)' }}>
                        <SizeMe>
                          {({ size }) => (
                            <InViewMonitor
                              onInView={() => handleInView(el)}
                              onNotInView={() => handleNotInView(el)}
                              repeatOnInView
                              threshold={0.5}
                            >
                              <PdfPage key={index} pageNumber={el.index} width={size.width || 1000} renderAnnotationLayer={false} />
                            </InViewMonitor>
                          )}
                        </SizeMe>
                      </div>
                    ))}
                  </Document>
                  {/* {isMobile ? (
                    <>
                      {pdf_pages.map((el, index) => (
                        <div key={index} ref={listRefs.current[index]} style={{ position: 'relative', margin: '1.5rem 0px', boxShadow: '0px 10px 10px -5px rgba(0,0,0,0.5)' }}>
                          <InViewMonitor
                            onInView={() => handleInView(heatmapData[index])}
                            onNotInView={() => handleNotInView(heatmapData[index])}
                            repeatOnInView
                            threshold={0.5}
                          >
                            <img src={`${process.env.NODE_ENV === 'development' ? '' : ''}${el?.image?.url}`} style={{ width: '100%' }} />
                          </InViewMonitor>
                        </div>
                      ))}
                    </>
                  ) : (
                    <Document
                      file={file}
                      onLoadSuccess={async (pdf) => {
                        console.log('end')
                        onDocumentLoadSuccess(pdf.numPages)
                      }}
                    >
                      {heatmapData.map((el, index) => (
                        <div key={index} ref={listRefs.current[index]} style={{ position: 'relative', margin: '1.5rem 0px', boxShadow: '0px 10px 10px -5px rgba(0,0,0,0.5)' }}>
                          <SizeMe>
                            {({ size }) => (
                              <InViewMonitor
                                onInView={() => handleInView(el)}
                                onNotInView={() => handleNotInView(el)}
                                repeatOnInView
                                threshold={0.5}
                              >
                                <PdfPage key={index} pageNumber={el.index} width={size.width || 1000} renderAnnotationLayer={false} />
                              </InViewMonitor>
                            )}
                          </SizeMe>
                        </div>
                      ))}
                    </Document>
                  )} */}
                </>
              )}
            </Container>
          </Page>
          {pdf?.contact_url && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                position: 'fixed',
                bottom: 24,
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
              }}
            >
              <Stack alignItems="center" sx={{ position: 'relative' }}>
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<SvgIconStyle src="/static/icons/ic_chat_bubble.svg" sx={{ width: 24, height: 24, color: '#fff' }} />}
                  endIcon={<SvgIconStyle src="/static/icons/ic_arrow_right.svg" sx={{ width: 24, height: 24, color: '#fff' }} />}
                  sx={{ width: 398, maxWidth: '80%', height: { xs: 60, md: 76 }, borderRadius: '76px', justifyContent: 'space-between', fontSize: 18, color: 'common.white' }}
                  onClick={() => window.open(pdf?.contact_url, '_blank')}
                  onMouseOver={() => setIsHoverButton(true)}
                  onMouseLeave={() => setIsHoverButton(false)}
                  ref={buttonRef}
                >
                  お問い合わせ
                </Button>
                <Box
                  sx={{
                    mt: 1.5,
                    ml: 0.5,
                    overflow: 'inherit',
                    boxShadow: (theme) => theme.customShadows.z20,
                    border: (theme) => `solid 2px ${isHoverButton ? '#EF9F11' : '#F1C832'}`,
                    borderRadius: '32px',
                    height: 32,
                    // width: 280,
                    maxWidth: '80%',
                    position: 'absolute',
                    top: -38,
                    right: '140',
                    backgroundColor: '#fff',
                  }}
                >
                  <ArrowStyle sx={{ borderColor: isHoverButton ? '#EF9F11 !important' : '#F1C832 !important' }} />
                  <Stack justifyContent="center" alignItems="center" sx={{ height: '100%', px: 3, py: 2 }}>
                    <Typography variant="overline" color={isHoverButton ? '#EF9F11 !important' : '#F1C832 !important'}>
                      もっと詳しく<Typography variant="overline" color="#212B36">話を聞いてみたい方は</Typography>
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          )}
        </MainStyle>
      </PageStyle>
    </RootStyle>
  );
}
/* eslint-disable*/