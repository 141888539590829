/* eslint-disable*/
import { Document, Page as PdfPage, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.js';
import { useState, useEffect } from 'react';
import {
  Container,
  Card,
  Typography,
  Stack,
  Grid,
  Link,
  Dialog,
  Button,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
// components
import Page from '../../components/Page';
import InViewMonitor from "react-inview-monitor";
import { SizeMe } from 'react-sizeme';
import { RootState, useDispatch, useSelector } from '../../redux/store';
import { getPdf, HeatmapData, getPdfPages } from '../../redux/slices/pdf';
import { Api } from '../../@types/api';
import { useParams, useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../routes/paths';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import SvgIconStyle from '../../components/SvgIconStyle';
import {
  PdfPageTable
} from '../../components/pdf';
import { fCurrency, fPercent, fTimeFromSeconds, fNumber } from 'utils/formatNumber';
// hooks
import useWindowSize from '../../hooks/useWindowSize';
import { kolApiConfig } from 'config';

// ----------------------------------------------------------------------

export default function PdfHeatmap() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [numPages, setNumPages] = useState<number | null>(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoading, pdf, filters, pdf_pages } = useSelector(
    (state: { pdf: Api.PdfListState }) => state.pdf
  );
  const [heatmapData, setHeatmapData] = useState<HeatmapData[]>([]);

  const [width, height] = useWindowSize();

  useEffect(() => {
    dispatch(getPdf(id));
    dispatch(getPdfPages(id));
  }, [dispatch]);

  const onDocumentLoadSuccess = (numPages: number) => {
    setNumPages(numPages);
    if (!pdf) return;

    const data = pdf.pages.map((p) => {
      return {
        id: p.id,
        index: p.index,
        time: p.time,
        view_count: p.view_count,
        isInview: false
      }
    })
    setHeatmapData(
      data
    );
  }

  const handleColor = (data: HeatmapData) => {
    const sumTime = heatmapData.reduce((a, x) => a += x.time, 0);
    const value = data.time / sumTime
    var h = (1.0 - value) * 240
    return "hsla(" + h + ", 100%, 50%, 0.5)";
  }

  const [openPageDialog, setOpenPageDialog] = useState<HeatmapData | null>(null);
  const handleOpenPageDialog = (heatmap: HeatmapData | null | undefined) => {
    if (!heatmap) {
      return;
    }
    setOpenPageDialog(heatmap);
  };
  const handleClosePageDialog = () => {
    setOpenPageDialog(null);
  };

  return (
    <Page title="PDF">
      <Container id="heat">
        <HeaderBreadcrumbs
          heading="PDFヒートマップ"
          links={[
            { name: 'PDFリスト', href: PATH_DASHBOARD.pdf.root },
            { name: 'PDFヒートマップ' }
          ]}
        />

        {pdf && (
          <Document
            file={pdf.file}
            onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}
          >
            <Stack spacing={7}>
              <Stack>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ p: { xs: 2, md: 3 }, height: '100%' }}>
                      <Stack spacing={2}>
                        <Typography variant="button" sx={{ fontSize: { xs: 16, md: 18 }, mb: 1 }}>閲覧時間TOP3</Typography>
                        {heatmapData.filter((p) => p.time > 0).sort((a, b) => b.time - a.time).map((page, index) => {
                          if (index > 2) {
                            return <></>;
                          }
                          return (
                            <Stack>
                              <Grid container spacing={2.5} alignItems="center">
                                <Grid item xs={2}>
                                  <Box component="img" src="/static/icons/cup.png" sx={{ width: 40, height: 40 }} />
                                </Grid>
                                <Grid item xs={6}>
                                  <Stack sx={{ width: 141 }}>
                                    <Link onClick={() => handleOpenPageDialog(page)}>
                                      {isMobile ? (
                                        <>
                                          {pdf_pages.filter((p) => p.index === page.index).map((el: any) => (
                                            <img src={`${process.env.NODE_ENV === 'development' ? kolApiConfig.publicURL : ''}${el?.image?.url}`} style={{ width: '100%' }} />
                                          ))}
                                        </>
                                      ) : (
                                        <PdfPage key={`page_${page.index}`} renderTextLayer={!isMobile} pageNumber={page.index} width={141} renderAnnotationLayer={false} />
                                      )}
                                    </Link>
                                  </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                  <Stack alignItems="flex-start">
                                    <Stack spacing={0.5}>
                                      <Typography variant="button" sx={{ fontSize: { xs: 16, md: 18 }, mb: 1 }}>{fTimeFromSeconds(page.time)}</Typography>
                                      <Typography variant="body2" color="#919EAB" sx={{ fontSize: { xs: 12, md: 14 } }}>{`p.${page.index}`}</Typography>
                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Stack>
                          )
                        })}
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ p: 3, height: '100%' }}>
                      <Stack spacing={2}>
                        <Typography variant="button" sx={{ fontSize: { xs: 16, md: 18 }, mb: 1 }}>閲覧回数TOP3</Typography>
                        {heatmapData.filter((p) => p.view_count > 0).sort((a, b) => b.view_count - a.view_count).map((page, index) => {
                          if (index > 2) {
                            return <></>;
                          }
                          return (
                            <Stack>
                              <Grid container spacing={2.5} alignItems="center">
                                <Grid item xs={2}>
                                  <Box component="img" src="/static/icons/cup.png" sx={{ width: 40, height: 40 }} />
                                </Grid>
                                <Grid item xs={6}>
                                  <Stack sx={{ width: 141 }}>
                                    <Link onClick={() => handleOpenPageDialog(page)}>
                                      {isMobile ? (
                                        <>
                                          {pdf_pages.filter((p) => p.index === page.index).map((el: any) => (
                                            <img src={`${process.env.NODE_ENV === 'development' ? kolApiConfig.publicURL : ''}${el?.image?.url}`} style={{ width: '100%' }} />
                                          ))}
                                        </>
                                      ) : (
                                        <PdfPage key={`page_${page.index}`} renderTextLayer={!isMobile} pageNumber={page.index} width={141} renderAnnotationLayer={false} />
                                      )}
                                    </Link>
                                  </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                  <Stack alignItems="flex-start">
                                    <Stack spacing={0.5}>
                                      <Typography variant="button" sx={{ fontSize: { xs: 16, md: 18 }, mb: 1 }}>{`${page.view_count}回`}</Typography>
                                      <Typography variant="body2" color="#919EAB" sx={{ fontSize: { xs: 12, md: 14 } }}>{`p.${page.index}`}</Typography>
                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Stack>
                          )
                        })}
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ p: 3, height: '100%' }}>
                      <Stack spacing={2}>
                        <Typography variant="button" sx={{ fontSize: { xs: 16, md: 18 }, mb: 1 }}>閲覧ユーザー数TOP3</Typography>
                        {pdf.pages.filter((p) => p.user_count > 0).sort((a, b) => b.user_count - a.user_count).map((page, index) => {
                          if (index > 2) {
                            return <></>;
                          }
                          return (
                            <Stack>
                              <Grid container spacing={2.5} alignItems="center">
                                <Grid item xs={2}>
                                  <Box component="img" src="/static/icons/cup.png" sx={{ width: 40, height: 40 }} />
                                </Grid>
                                <Grid item xs={6}>
                                  <Stack sx={{ width: 141 }}>
                                    <Link onClick={() => handleOpenPageDialog(heatmapData.find((h) => h.index === page.index))}>
                                      {isMobile ? (
                                        <>
                                          {pdf_pages.filter((p) => p.index === page.index).map((el: any) => (
                                            <img src={`${process.env.NODE_ENV === 'development' ? kolApiConfig.publicURL : ''}${el?.image?.url}`} style={{ width: '100%' }} />
                                          ))}
                                        </>
                                      ) : (
                                        <PdfPage key={`page_${page.index}`} renderTextLayer={!isMobile} pageNumber={page.index} width={141} renderAnnotationLayer={false} />
                                      )}
                                    </Link>
                                  </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                  <Stack alignItems="flex-start">
                                    <Stack spacing={0.5}>
                                      <Typography variant="button" sx={{ fontSize: { xs: 16, md: 18 }, mb: 1 }}>{`${page.user_count}人`}</Typography>
                                      <Typography variant="body2" color="#919EAB" sx={{ fontSize: { xs: 12, md: 14 } }}>{`p.${page.index}`}</Typography>
                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Stack>
                          )
                        })}
                      </Stack>
                    </Card>
                  </Grid>
                </Grid>
              </Stack>
              <Card sx={{ px: 1, py: { xs: 2, md: 3 }, pb: 2 }}>
                <Typography variant="h6" sx={{ mb: 2, ml: 1 }}>サマリ</Typography>
                <PdfPageTable
                  info={pdf.pages}
                  isLoad={!pdf}
                />
              </Card>

              <Stack spacing={3}>
                <Typography variant="h6" sx={{ ml: 2 }}>ヒートマップ一覧</Typography>
                <Stack>
                  <Grid container spacing={2}>
                    {heatmapData.map((el, index) => (
                      <Grid key={index} item xs={12} md={3}>
                        <Link onClick={() => handleOpenPageDialog(el)}>
                          {isMobile ? (
                            <div style={{ position: 'relative' }}>
                              {pdf_pages.filter((p) => p.index === el.index).map((el: any) => (
                                <img src={`${process.env.NODE_ENV === 'development' ? kolApiConfig.publicURL : ''}${el?.image?.url}`} style={{ width: '100%' }} />
                              ))}
                              <div style={{ background: handleColor(el), width: '100%', height: '100%', position: 'absolute', top: '0', left: '0' }}></div>
                            </div>
                          ) : (
                            <SizeMe>
                              {({ size }) => (
                                <div style={{ position: 'relative' }}>
                                  <PdfPage key={`page_${el.index}`} renderMode={isMobile ? 'svg' : 'canvas'} renderTextLayer={!isMobile} pageNumber={el.index} width={size.width || 200} renderAnnotationLayer={false} />
                                  <div style={{ background: handleColor(el), width: '100%', height: '100%', position: 'absolute', top: '0', left: '0' }}></div>
                                </div>
                              )}
                            </SizeMe>
                          )}
                        </Link>
                        <Typography variant="overline" color="#919EAB">{el.index}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Stack>
            </Stack>

            <Dialog
              open={Boolean(openPageDialog)}
              onClose={handleClosePageDialog}
              maxWidth="lg"
              PaperProps={{
                sx: {
                  borderRadius: '0px !important',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  position: 'relative',
                }
              }}
            >
              {pdf?.file && (
                <Stack sx={{ minWidth: { xs: width * 0.9, md: 959 }, maxWidth: '90%', px: { xs: 0, md: 3 }, py: 4 }}>
                  <PdfPage key={`page_${openPageDialog?.index}`} pageNumber={openPageDialog?.index} width={isMobile ? width * 0.9 : 959} renderAnnotationLayer={false} />
                  <Link
                    component="button"
                    color="text.primary"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 999,
                    }}
                    onClick={handleClosePageDialog}
                  >
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        backgroundColor: '#fff',
                        borderRadius: '24px',
                        color: 'text.primary',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <SvgIconStyle src="/static/icons/ic_x.svg" sx={{ width: 24, height: 24 }} />
                    </Box>
                  </Link>
                </Stack>
              )}
            </Dialog>
          </Document>
        )}


      </Container>
    </Page>
  );
}
/* eslint-disable*/