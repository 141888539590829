import { Theme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Table(theme: Theme) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover
            }
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          ...theme.typography.subtitle2,
          padding: isMobile ? theme.spacing(1.5) : theme.spacing(2)
        },
        head: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
          '&:first-of-type': {
            paddingLeft: { sm: theme.spacing(1.5), md: theme.spacing(3) },
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
          },
          '&:last-of-type': {
            paddingRight: { sm: theme.spacing(1.5), md: theme.spacing(3) },
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
          }
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`
        },
        body: {
          fontWeight: 400,
          '&:first-of-type': {
            paddingLeft: { sm: theme.spacing(1.5), md: theme.spacing(3) }
          },
          '&:last-of-type': {
            paddingRight: { sm: theme.spacing(1.5), md: theme.spacing(3) }
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`
        },
        toolbar: {
          height: 64
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius
          }
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2
        }
      }
    }
  };
}
