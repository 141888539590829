/* eslint-disable*/
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%', color: 'text.primary' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'GENERAL',
    items: [
      {
        title: 'PDFリスト',
        path: PATH_DASHBOARD.pdf.root,
        icon: ICONS.dashboard,
        children: [
          { title: 'PDFヒートマップ', path: PATH_DASHBOARD.pdf.heatmap },
        ],
      },
      {
        title: '企業リサーチ(DEMO)',
        path: PATH_DASHBOARD.research.root,
        icon: ICONS.analytics,
      }
    ]
  }
];

export default sidebarConfig;

/* eslint-disable*/